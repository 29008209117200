import React from "react";
import "./css/FooterSection.scss"
export default function FooterSection() {
    return <div>
        <footer className="footer-setion">
            <div className="footer-bottom medical-bg pt-4 pb-4">
                <div className="container">
                    <div className="row text-center text-md-left align-items-center">
                        <div className="col-md-6 col-lg-5"><p
                            className="text-md-left copyright-text text-dark pb-0 mb-0">Copyrights © 2022. All
                            rights reserved by
                            <a href="/#" aria-label="Go to homepage"> Cardiomedive</a></p></div>
                        <div className="col-md-6 col-lg-7">
                            <div className="icons">
                                <ul className="social-list list-inline list-unstyled text-md-right">
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/cardiomedive/"
                                           target="_blank"
                                           title="Instagram"
                                           className="icon icon--instagram"
                                           aria-label="Go to Instagram"
                                        >
                                            <i className="ri-instagram-line ti-instagram"/>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://mobile.twitter.com/cmedive"
                                           target="_blank"
                                           title="Twitter"
                                           className="icon icon--twitter"
                                           aria-label="Go to Twitter"
                                        >
                                            <i className="ri-twitter-line ti-twitter"/>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://web.facebook.com/CardioMedive?_rdc=1&_rdr"
                                           target="_blank"
                                           title="Facebook"
                                           className="icon icon--linkedin"
                                           aria-label="Go to Facebook"
                                        >
                                            <i className="ri-linkedin-line ti-facebook"/>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/cardiomedive/"
                                           target="_blank"
                                           title="Linkedin"
                                           className="icon icon--linkedin"
                                           aria-label="Go to Linkedin"
                                        >
                                            <i className="ri-linkedin-line ti-linkedin"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
}