import "./css/Section1.scss"
import React from "react";


export default function Section1() {

    const data = {
        title: "Be one technological leap ahead of heart disease",
        description: "CardioMedive™ offers telemedicine platforms a unique integrated care hardware & software ecosystem for heart disease management.",
        bgImage: "my-images/01-header-image.jpg",
        images: {
            img: "my-images/Product-in-hand.png"
        }
    };

    return <section className="section1 hero-section hero-section-2 ptb-100" id="home"
        style={{
            background: `url('img/curve-bg.jpg')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "center / cover",
        }}
    >

        <div className="container">
            <div className="row align-items-center justify-content-between ">
                <div className="col-md-6 col-sm-12 title-col">
                    <div className="hero-content-left ptb-100">
                        <h1 className="medical-color pb-4 semibold">{data.title}</h1>

                        <p className="lead">{data.description}</p>
                        <div className="video-promo-content mt-4 d-flex align-items-center">
                            <a href={"https://www.youtube.com/watch?v=AHau0pYOOhg"} className="popup-youtube video-play-icon" aria-label="see cardiomedive video"/>
                            <span className="ml-3">Watch a demo</span>
                        </div>
                    </div>
                </div>


                <div className="col-md-5 col-sm-12 image-col">
                    <div className="hero-animation-img">
                        <img
                            className="img-fluid d-block animation-one"
                            src={data.images ? data.images.img : ""}
                            width="462"
                            height="585"
                            alt="cardiomedive-badge"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
}


