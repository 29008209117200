import React from 'react';
import ReactDOM from 'react-dom'
import App from "./App";
import './index.css';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route>
                        <App/>
                    </Route>
                </Switch>
            </ScrollToTop>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);