import "./css/Section3.scss"
import React from "react";

export default function Section3() {

    const data = {
        items: [
            {
                title: "Medical-grade device",
                description:
                    "ICardioMedive is a medical system clinically-tested and certified.",
                image: "my-images/03-icon-1.svg"
            },
            {
                title: "Increase store trust",
                description:
                    "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
                icon: "ti-shield icon-color-2 icon rounded"
            },
            {
                title: "Growth guaranteed",
                description:
                    "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
                icon: "ti-cup icon-color-3 icon rounded"
            }
        ],
        title: "IT'S THE FUTURE OF CARDIOLOGY",
        description: "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts"
    }

    return <section className="section3 pt-5 pb-100 gray-light-bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-md-11">
                    <div className="section-heading text-center mb-5">
                        <div className="h5 medical-color spaceing"><b>{data.title}</b></div>
                    </div>
                </div>
            </div>

            <div className="row no-gutters items-row">
                <div className="col-lg-4 col-md-4 col-sm-12 item-col">
                    <div
                        className="item single-services rounded gray-light-bg single-feature text-center p-5 h-100">
                        <span>
                            <img src="my-images/03-icon-1.svg"
                                 width="147"
                                 height="150"
                                 className="img-fluid d-block center"
                                 alt="Medical-grade device"
                            />
                        </span>
                        <div className="feature-content">
                            <h2 className="medical-color semibold">Medical-grade device</h2>
                            <p>CardioMedive is a medical system,
                                clinically-tested and certified.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 item-col">
                    <div
                        className="item single-services rounded gray-light-bg single-feature text-center p-5 h-100">
                         <span>
                            <img src="my-images/03-icon-2.svg"
                                 width="147"
                                 height="150"
                                 className="img-fluid d-block center "
                                 alt="All-in-one philosophy"
                            />
                        </span>
                        <div className="feature-content">
                            <h2 className="medical-color semibold"> All-in-one philosophy</h2>
                            <p>Designed with simplicity at its core,
                                the system offers unprecedented
                                ease of use.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 item-col">
                    <div
                        className="item single-services rounded gray-light-bg single-feature text-center p-5 h-100">
                         <span>
                            <img src="my-images/03-icon-3.svg"
                                 width="147"
                                 height="150"
                                 className="img-fluid d-block center"
                                 alt="User-friendly & affordable"
                            />
                        </span>
                        <div className="feature-content">
                            <h2 className="medical-color semibold">User-friendly & affordable</h2>
                            <p>Designed for real people.
                                Low price per unit thanks to efficient,
                                innovative design & engineering.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

}