import "./css/Section8.scss";


export default function Section8() {

    return <section className="section8 pt-5 pb-100 gray-light-bg" id="use_cases">
        <div className="container">

            <div className="row justify-content-center">
                <div className="col-md-12 col-sm-12">
                    <div className="section-heading text-center mb-5">
                        <div className="h5 medical-color spaceing"> <b>THE SYSTEM AT A GLANCE</b></div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center img-row">
                <img
                    className="img-fluid d-block"
                    src="my-images/07-diagrama-produs.png"
                    height="220"
                    width="1719"
                    alt="cardiomedive product diagram"
                />
            </div>


            <div className="row justify-content-center">
                <div className="col-md-8 col-sm-8">
                    <div className="section-heading text-center mb-5 mt-5">
                        <h1 className="h4">CardioMedive offers healthcare providers products and
                            services, which in turn enable them to create their own revenue streams.</h1>
                    </div>
                </div>
            </div>

            <div className="row no-gutters items">
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Hardware-enabled and HaaS</b></h1>
                            <p>Device sales and subscriptions (hardware as a service).</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Consumables</b></h1>
                            <p>Custom electrode disposable parts.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Diagnostics</b></h1>
                            <p>Flat monthly fee for unlimited reports,
                                or pay-per-report. </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Telerehabilitation</b></h1>
                            <p>Flat monthly fee for unlimited reports,
                                or pay-per-report. </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Teleconsultation</b></h1>
                            <p>Open API for 3rd party analytics software
                                development, pay-per-teleconsultation.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Clinical trials</b></h1>
                            <p>Support for novel vaccine, drugs, pharmacy
                                through patient management program.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Licensing</b></h1>
                            <p>Enable healthcare providers to brand and
                                customize their device and platform.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 item">
                    <div
                        className="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100">
                        <div className="feature-content">
                            <h1 className="h5"><b>Patient/employee reported well-being</b></h1>
                            <p>Flat monthly fee for unlimited reports,
                                or pay-per-report. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

}