import "./css/Section5.scss"

export default function Section5() {

    return <section className="section5 pt-100 pb-100 gray-light-bg">
        <div className="container">
            <div >
                <div className="row no-gutters description">
                    <div className="col-md-12">
                        <h1 className="h3 medical-color text-center">
                            <b>CardioMedive</b> covers the full disease management protocol,
                            <p>with a unique integrated care longitudinal approach.</p>
                        </h1>
                    </div>
                </div>

                <div className="row no-gutter steps text-center justify-content-center pt-3">
                    <div className="col-lg-2 col-md-4 col-sm-12"><MyStep step={"1"} label={"Remote Pre-screening"}/></div>
                    <div className="col-lg-2 col-md-4 col-sm-12"><MyStep step={"2"} label={"Remote Diagnostic"}/></div>
                    <div className="col-lg-2 col-md-4 col-sm-12"><MyStep step={"3"} label={"Follow-up teleconsultation"}/></div>
                    <div className="col-lg-2 col-md-4 col-sm-12"><MyStep step={"4"} label={"Long-term at home monitoring"}/></div>
                    <div className="col-lg-2 col-md-4 col-sm-12"><MyStep step={"5"} label={"Well-being monitoring"}/></div>
                    <div className="col-lg-2 col-md-4 col-sm-12"><MyStep step={"6"} label={"Telerehabilitation"}/></div>
                </div>

            </div>
        </div>
    </section>
}


function MyStep({step, label}: {step: string, label: string}) {
    return <div>
        <div className="row"><span className="fa fa-circle step">{step}</span></div>
        <div className="row"><span className="label">{label}</span></div>
    </div>
}