import React from "react";

export default function Section9() {

    return <section className="pt-5">

        <div className="container">
            <div className="row mb-5">
                <div className="col-md-12 col-lg-12">
                    <h1 className="h4 text-center medical-color semibold">
                        One small device with bio-sensors to measure and record various life-sustaining vital signs
                    </h1>
                </div>
            </div>

            <div className="row  no-gutters items-row justify-content-center">
                <Vital
                    content={"1-2 lead ECG"}
                    imgSrc={"my-images/vitals/01-vitals.svg"}
                />
                <Vital
                    content={"Heart Rate"}
                    imgSrc={"my-images/vitals/02-vitals.svg"}
                />
                <Vital
                    content={"Heart rate variability"}
                    imgSrc={"my-images/vitals/03-vitals.svg"}
                />
                <Vital
                    content={"Respiration Rate"}
                    imgSrc={"my-images/vitals/04-vitals.svg"}
                />
                <Vital
                    content={"Skin temperature"}
                    imgSrc={"my-images/vitals/05-vitals.svg"}
                />
            </div>
            <div className="row  no-gutters items-row justify-content-center">
                <Vital
                    content={"Movement"}
                    imgSrc={"my-images/vitals/06-vitals.svg"}
                />
                <Vital
                    content={"Electronic Stethoscope"}
                    imgSrc={"my-images/vitals/07-vitals.svg"}
                />
                <Vital
                    content={"Blood Saturation"}
                    imgSrc={"my-images/vitals/08-vitals.svg"}
                />
                <Vital
                    content={"Cuffless Blood Pressure"}
                    imgSrc={"my-images/vitals/09-vitals.svg"}
                />
                <Vital
                    content={"Body posture"}
                    imgSrc={"my-images/vitals/10-vitals.svg"}
                />
            </div>
            <div className="row  no-gutters items-row justify-content-center">
                <Vital
                    content={"Mean Arterial Pressure"}
                    imgSrc={"my-images/vitals/11-vitals.svg"}
                />
                <Vital
                    content={"Systemic vascular Resistance"}
                    imgSrc={"my-images/vitals/12-vitals.svg"}
                />
                <Vital
                    content={"Stress level"}
                    imgSrc={"my-images/vitals/13-vitals.svg"}
                />
                <Vital
                    content={"Cardiac Output"}
                    imgSrc={"my-images/vitals/14-vitals.svg"}
                />
                <Vital
                    content={"Sweat Level"}
                    imgSrc={"my-images/vitals/15-vitals.svg"}
                />
            </div>
        </div>
    </section>
}


export function Vital({imgSrc, content} : {imgSrc: string, content: string}) {

    return <div className="col-lg-2 col-md-2 col-sm-4 item-col mb-4 col-4">
        <div className="item single-services rounded single-feature text-center ">
                        <span>
                            <img src={imgSrc}
                                 className="img-fluid center"
                                 width="80"
                                 height="80"
                                 alt={"cardiomedive vital " + content}
                            />
                        </span>
            <div className="feature-content">
                <p>{content}</p>
            </div>
        </div>
    </div>

}