import "./css/Section10.scss"
import React, {useState} from "react";

export default function Section10() {

    return <section className="section10 pt-5 pb-100" id="about">
        <div className="container">
            <div className="row justify-content-center our-team mb-0">
                <h1 className="h5 medical-color spaceing semibold">OUR TEAM</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-9 col-sm-9">
                    <div className="section-heading text-center mb-5 mt-5">
                        <h2 className="h4">CardioMedive Healthcare is a Romanian-based startup incorporated in 2019, developing highly
                            innovative, disruptive technologies that address a broad spectrum of heart disease, with a
                            single device and software platform.
                        </h2>
                        <h2 className="h4">The CardioMedive Healthcare team brings together medical device development, academic,
                            management and entrepreneurial experience, with over three decades of expertise in biosensor
                            engineering and medical product development.</h2>
                    </div>
                </div>
            </div>

            <div className="row our-team-items">
                <Member2 nume={"Ion Mocanu"}
                         descriere={["Founder & CEO", "A PhD student in medical biosensor design with over 15 years of experience in electronic device engineering, Ion has been passionately developing the CardioMedive project since 2018"]}
                         img={"my-images/team/1.png"} colTypeClass={"col-lg-4 col-md-4 col-6 "}
                         profil={"https://www.linkedin.com/in/ion-mocanu-22259372"}/>
                <Member2 nume={"Adrian LUPU"}
                         descriere={["Co-founder", "Electronic Engineer &", "HW PCB Layout", "25 years experience"]}
                         img={"my-images/team/3.png"} colTypeClass={"col-lg-4 col-md-4 col-6 "}
                         profil={"https://www.linkedin.com/in/adrian-lupu-18a81a142"}/>
                <Member2 nume={"Bogdan RADU"}
                         descriere={["Co-founder", "Mechanical Industrial", "Design", "7 years experience"]}
                         img={"my-images/team/4.png"} colTypeClass={"col-lg-4 col-md-4 col-6 "}
                         profil={"https://www.linkedin.com/in/radu-bogdan-971455173"}/>

                <Member2 nume={"Mihaela PORUMB"} descriere={["AI\\ML Researcher", "10 years experience"]}
                         img={"my-images/team/5.png"} colTypeClass={"col-lg-4 col-md-4 col-6 "}
                         profil={"https://www.linkedin.com/in/mihaela-porumb-392836156"}/>
                <Member2 nume={"Cristian LUPASCU"}
                         descriere={["Security Solution", "Architect Consultant", "10 years experience"]}
                         img={"my-images/team/6.png"} colTypeClass={"col-lg-4 col-md-4 col-6 "}
                         profil={"https://www.linkedin.com/in/cristian-lupascu-phd-6675a463"}/>
                <Member2 nume={"Dr. Brânduşa BITEL"}
                         descriere={["Clinical Research", "Managing Partner", "SCALA Clinical Research"]}
                         img={"my-images/team/7.png"} colTypeClass={"col-lg-4 col-md-4 col-6 "}
                         profil={"https://www.linkedin.com/in/brandusabittel"}/>
            </div>


            <div className="row justify-content-center advisory-panel-team mt-5">
                <h1 className="h5 medical-color spaceing semibold">ADVISORY PANEL TEAM</h1>
            </div>
            <div className="row justify-content-center advisory-panel-team-items">
                <Member2 nume={"Dr. Gabriel TATU-CHIOIU"}
                         descriere={["Ex-President-elect of the Romanian", "Society of Cardiology", "Owner Cardiomed", "Co-Founder & Co-Owner Cardioscience"]}
                         img={"my-images/team/8.png"} colTypeClass={"col-lg-3 col-md-6 col-6"}
                         profil={"https://www.linkedin.com/in/gabriel-tatu-chitoiu-056277b1"}/>
                <Member2 nume={"Dr. Ana FRUNTELATA"}
                         descriere={["Cardiologist at Monza Hospital", "PhD in Cardiology", "Fellow of the European Society", "Author of journals and books in cardiology"]}
                         img={"my-images/team/9.png"} colTypeClass={"col-lg-3 col-md-6 col-6"}/>
                <Member2 nume={"Dr. Daniel GANEA"}
                         descriere={["Competences in endoscopic surgery and EKG", "Fellow of the Romanian Rhinology Society", "Worked in ambulatory and emergency stations"]}
                         img={"my-images/team/10.png"} colTypeClass={"col-lg-3 col-md-6 col-6"}/>
                <Member2 nume={"Andrei Vasile"}
                         descriere={["Business Advisor", "Managing Partner", "SanoPass România & Moldova"]}
                         img={"my-images/team/11.png"} colTypeClass={"col-lg-3 col-md-6 col-6"}
                         profil={"https://www.linkedin.com/in/vasileandrei"}/>
            </div>
        </div>

    </section>
}


function Member2({
                     nume,
                     descriere,
                     img,
                     colTypeClass,
                     profil
                 }: { nume: string, descriere: string[], img: string, colTypeClass: string, profil?: string | undefined }) {

    const [isHovered, setIsHovered] = useState<boolean>(false);

    return <div key={nume} className={"item-col " + colTypeClass} onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
        <div className="member-item single-services rounded medical-bg single-feature text-center pt-5 h-100">
            <span>
                {profil && <a href={profil} aria-label={"go to linkedin " + nume}><i className="ti-linkedin"/></a>}
                <img src={img}
                     className="img-fluid d-block center"
                     width="179"
                     height="179"
                     alt={"cardiomedive member " + nume}
                />
            </span>
            <div className="feature-content">

                <h1 className="h5 text-color-medical">{nume}</h1>
                <div className={isHovered ? "isHovered" : "hidden"}>
                    {descriere.map(descriere => {
                        return <div key={descriere} className="justify-content-center text-center">{descriere}</div>
                    })}
                </div>
            </div>
        </div>
    </div>
}