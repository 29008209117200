import "./css/JoinOurTeam.scss"
import React, {useRef, useState} from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";


export default function JoinOurTeam(this: any) {
    const [email, setEmail] = useState('')
    const [nume, setNume] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [mesaj, setMesaj] = useState('')
    const [result, setResult] = useState("");
    const [show, setShow] = useState(false);
    const reRef = useRef<ReCAPTCHA>();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSumbit = async (e: any) => {
        e.preventDefault();

        if ((email || nume || phoneNumber || mesaj) === '') {
            alert("Nu ati completat toate campurile!")
            return
        } else {
            const token = await reRef.current.executeAsync();
            reRef.current.reset();

            const form = $(e.target);

            $.ajax({
                type: "POST",
                url: "https://cardiomedive.eu/send_mail.php",
                //url: "http://localhost:8080/send_mail.php",
                data: form.serialize().concat("&token=").concat(token),
                success(data) {
                    handleShow()
                    console.log("succes")
                    setMesaj('');
                    setEmail('');
                    setPhoneNumber('')
                    setNume('')
                    setResult(data);
                },
                error: function (xhr, status, err) {
                    console.error(xhr, status, err.toString());
                    console.log("A aparut o eoare, incercati mai tarziu.")
                }
            });
        }
    };

    return <section className=" pb-5 joinourteam">

        <ReCAPTCHA
            sitekey="6Le03UEjAAAAACIQ1D2Z-_k938ri9TMY5jTbMWQr"
            size="invisible"
            ref={reRef}
        />

        <div className="container">
            {/*<div className="row justify-content-center our-team mb-0">*/}
            {/*    <h1 className="h5 medical-color spaceing semibold">Join our team</h1>*/}
            {/*</div>*/}
            <div className="row justify-content-center ">
                    <h1 className="medical-color pt-4 semibold">Join our team</h1>
            </div>
            <div className="justify-content-between pt-3">
                <div>
                    <form onSubmit={(event) => handleSumbit(event)}>
                        <div className="form-group">

                            <div className="row">
                                <div className="col-md-6">

                                    <input name="nume"
                                           required={true}
                                           type="text"
                                           className="form-control mt-3"
                                           aria-describedby="emailHelp"
                                           placeholder="Full name *"
                                           value={nume}
                                           onChange={(e) => setNume(e.target.value)}/>
                                    <input name="email"
                                           required={true}

                                           type="email"
                                           className="form-control mt-3"
                                           aria-describedby="emailHelp"
                                           placeholder="Email *"
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <input name="phoneNumber"
                                           type="tel"
                                           required={true}
                                           className="form-control mt-3"
                                           aria-describedby="emailHelp"
                                           placeholder="Phone number *"
                                           value={phoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                                </div>
                                <div className="col-md-6 justify-content-center">

                                    <textarea name="message"
                                           rows={5}
                                           maxLength={1000}
                                              required={true}
                                           className="form-control mt-3 "
                                           aria-describedby="emailHelp"
                                           placeholder="Short description (up to 1000 characters) *"
                                           value={mesaj}
                                           onChange={(e) => setMesaj(e.target.value)}/></div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-news align-content-center mt-3">Join Us</button>
                    </form>
                    <Modal className="boxModal gray-light-bg" show={show} onHide={handleClose}>
                        <Modal.Header className="headerModal" closeButton>
                            <Modal.Title>Success!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className=" gray-light-bg">
                            <p>Thank you for your interest</p>
                            <p>Our team will contact you!</p>
                        </Modal.Body>
                        <Modal.Footer className=" gray-light-bg">
                            <Button variant="outline-primary" className="btn-news" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>

        </div>
    </section>

}