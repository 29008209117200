import HeaderSection from "../sections/HeaderSection";
import Section13 from "../sections/Section13";
import FooterSection from "../sections/FooterSection";
import React from "react";
import {Link} from "react-router-dom";
import news from "../data/news.json"
import {NewsType} from "../data/NewsType";
import "./NewsContent/NewsContent.scss";
import ReactMarkdown from 'react-markdown'


export default function Newspage() {

    return <div>
        <HeaderSection/>
        <div className="pt-100 pb-5 ">
            <section className="">
                <div className="container ">
                    <div className="row justify-content-center our-team mb-0">
                        <h1 className="h2 spaceing semibold">Latest News</h1>
                    </div>
                </div>
            </section>
        </div>
        <div className="container mb-5">
            <section className="newsSection mb-4">
                <div className="container">
                    <div className="row equal mt-3 ">
                        {news.items.map((item: NewsType) => {
                            return <NewsCard key={item.title} item={item}/>;
                        })}
                    </div>
                </div>
            </section>
        </div>

        <Section13/>

        <FooterSection/>
    </div>


    function NewsCard({item}:{item: NewsType}) {
        return <div className="news-card col-md-4 col-lg-4 rounded-top ">
            <div className="single-promo single-promo-hover rounded-top  h-100  gray-light-bg mb-4"  >
                <div className="text-left ml-2 date pt-2">
                    <p className="mb-0">Published by {item.publishedBy}</p><p>{item.date} | {item.type}</p>
                </div>
                <div className="image-card">
                    <img
                        src={item.image}
                        alt="promo"
                        className="img-fluid img-full-box"
                    />
                </div>
                <div className="pl-4 mb-5 text-card">
                    <h5 className="">{item.title}</h5>
                    <div  >
                        {item.summary.map(p => {
                            // return <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(p)}} >
                            return <ReactMarkdown>{p}</ReactMarkdown>
                        })}
                    </div >

                </div>

                <div className="read-more-link text-center fixed-bottom rounded-bottom pt-3" >
                    <Link to={"/news/content/" + item.title} className=" text-decoration-none">
                        <p className="medical-color text-decoration-none">Read More</p>
                    </Link>
                </div>
            </div>
        </div>
    }
}