import React from "react";
import "./css/Section13.scss";

export default function Section13() {

    return <div className="section section13 gray-light-bg pt-5 pb-5" id="contact">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-7 col-md-6 col-sm-12 mb-7 mb-lg-0 image-col">
                        <div className="footer-nav-wrap ">
                            <img src="my-images/00-logo2-medical.svg"
                                 width="500"
                                 height="276"
                                 className="img-fluid mb-3 medical-color"
                                 alt="cardiomedive logo"
                            />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 ml-auto mb-5 mb-lg-0 details-col">
                        <div className="footer-nav-wrap">
                            <h1 className="h5 mb-3">Our location</h1>
                            <ul className="list-unstyled">
                                <li className="mb-2"><span
                                    className="ti-location-pin mr-2"/><strong>Address: </strong>
                                    Bvd. Gheorghe Sincai 11
                                    <br/>ZIP 040313, Bucharest, Romania
                                </li>
                                <li className="mb-2"><span
                                    className="ti-mobile mr-2"/><strong>Phone: </strong> <a
                                    href="tel:+61283766284" aria-label="phone number"> +40 762 148 768</a></li>
                                <li className="mb-2"><span
                                    className="ti-email mr-2"/><strong>Email: </strong><a
                                    href="mailto:info@cardiomedive.eu" aria-label="mail"> info@cardiomedive.eu</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        {/*</div>*/}
    </div>

}