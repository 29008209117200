import "./css/Section2.scss"
import React from "react";

export default function Section2() {

    return <div className="section2">
        <div className="container">
            <div className="row equal justify-content-center">
                <div className="col-md-5 col-lg-5">
                    <img
                        className="img-fluid d-block"
                        src="my-images/02-produs-cutie.png"
                        width="1032"
                        height="1374"
                        alt="cardiomedive-product-box"
                    />
                </div>

                <div className="col-md-5 col-lg-5">
                    <p className="lead text-center">
                        CardioMedive is a multi-role device &
                        cloud platform offering <b className="medical-color">an unprecedented range
                        of remote investigation and monitoring </b>for most stages of cardiovascular disease.
                    </p>
                </div>
            </div>
        </div>
    </div>

}