import React from "react";
import "./css/Section12.scss";

export default function Section12() {
    return <section className="section12 pb-100 pt-5">
        <div className="container">
            <div className="row pb-100 justify-content-center ">
                <h1 className="h5 medical-color spaceing text-center"> <b>CARDIOMEDIVE &nbsp; PARTNERS&nbsp;  & &nbsp; COLLABORATORS</b></h1>
            </div>
            <div className="row pb-100">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-11 container-sm medical-bg single-services single-feature-hover single-feature text-center mb-5 rounded partners">
                            <p className="lead mb-0 text-white">
                                Early adopter partners
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <img
                            src="my-images/partners/early-adopter-partners.svg"
                            width="319"
                            height="130"
                            alt={"cardiomedive partners"}
                            style={{filter:"grayscale(100%)"}}
                            className="img-fluid image-gray"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-11 container-sm medical-bg single-services single-feature-hover single-feature text-center mb-5 rounded partners">
                            <p className="lead mb-0 text-white">
                                Strategic partners
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <img
                            src="my-images/partners/strategic-partners.svg"
                            width="620"
                            height="242"
                            alt="client"
                            style={{filter:"grayscale(100%)"}}
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-11 container-sm medical-bg single-services single-feature-hover single-feature text-center mb-5 rounded partners">
                            <p className="lead mb-0 text-white">
                                Accelerators
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <img
                            src="my-images/partners/accelerators.svg"
                            width="385"
                            height="142"
                            alt="client"
                            style={{filter:"grayscale(100%)"}}
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-11 container-sm medical-bg single-services single-feature-hover single-feature text-center mb-5 rounded partners">
                            <p className="lead mb-0 text-white">
                                Press
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <img
                            src="my-images/partners/press.svg"
                            width="469"
                            height="140"
                            alt="client"
                            style={{filter:"grayscale(100%)"}}
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>

    </section>
}