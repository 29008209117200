import React, {useRef, useState} from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

type Animation = {
    start: number,
    end: number,
    duration: number;
}

const DEFAULT_DURATION = 800;
const START_POSITION = 55;
const END_POSITION_1 = 35;
const END_POSITION_2 = 80
const ANIMATIONS: Animation[] = [
    {
        start: START_POSITION,
        end: END_POSITION_1,
        duration: DEFAULT_DURATION,
    },
    {
        start: END_POSITION_1,
        end: END_POSITION_1,
        duration: 30,
    },
    {
        start: END_POSITION_1,
        end: END_POSITION_2,
        duration: DEFAULT_DURATION,
    },
];

export default function Slider() {

    const slider_config = {
        images: {
            image1: {
                imageUrl: "my-images/slider-img-1.png",
                alt: "without cardiomedive"
            },
            image2: {
                imageUrl: "my-images/slider-img-2.png",
                alt: "with cardiomedive"
            }
        },
        delimiterIconStyles: {
            width: '25px',
            height: '25px',
            backgroundSize: 'cover',
            borderRadius: 'none',
            // backgroundImage: 'url(my-images/)'
            color: "#00A8A8"
        }
    }

    const [delimiterPercentPosition, setDelimiterPercentPosition] = useState<number>(START_POSITION);
    const allAnimationsRef = useRef<Animation[]>([]);
    const animationStartTimeRef = useRef<number | null>(null);
    const animationPositionsRef = useRef<Animation | null>(null);
    const DEMONSTRATION_DELAY = 500;

    function timePhaseToCoordinadeDifferenceCoefficient(x: number) {
        return (Math.sin(x * Math.PI - Math.PI / 2) + 1) / 2;
    }

    const animate = (timestamp: number) => {
        let animationPositions = animationPositionsRef.current;

        if (!animationPositions) {
            const currentAnimation = allAnimationsRef.current.shift();
            if (!currentAnimation) {
                return;
            }
            animationPositions = animationPositionsRef.current = currentAnimation;
        }

        let animationStartTime = animationStartTimeRef.current;
        if (!animationStartTime) {
            animationStartTime = animationStartTimeRef.current = timestamp;
        }

        const {
            start: animationStartPosition,
            end: animationEndPosition,
            duration: animationDuration
        } = animationPositions;

        if (timestamp > animationStartTime + animationDuration) {
            setDelimiterPercentPosition(animationEndPosition);
            animationPositionsRef.current = null;
            animationStartTimeRef.current = null;
        } else {
            const animationPhase = (timestamp - animationStartTime) / animationDuration;
            const coordinatesDifference =
                (animationEndPosition - animationStartPosition)
                * timePhaseToCoordinadeDifferenceCoefficient(animationPhase);

            setDelimiterPercentPosition(animationStartPosition + coordinatesDifference);
        }


        window.requestAnimationFrame(animate);
    }

    const startAnimation = () => {
        allAnimationsRef.current = [...ANIMATIONS];
        setTimeout(() => window.requestAnimationFrame(animate), DEMONSTRATION_DELAY);
    };


    return <div className="section" id="whyus">
        <div className="container">
            <ReactBeforeSliderComponent
            onVisible={startAnimation}
            currentPercentPosition={delimiterPercentPosition}
            onChangePercentPosition={setDelimiterPercentPosition}
            delimiterIconStyles={slider_config.delimiterIconStyles}
            delimiterColor={"#00A8A8"}
            firstImage={slider_config.images.image2}
            secondImage={slider_config.images.image1}
        />
        </div>
    </div>
}
