import React from "react";
import "./css/Section6.scss"
import ReactOwlCarousel from "react-owl-carousel";

type Comment = {name: string, state: string, company: string, image: string, comment: string};

export default function Section6() {

    const testimonial = {
        title: "WHAT THE EXPERTS SAY",
        description:
            'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
        comments: [
            {
                name: "Dr. Gabriel TATU-CHIOIU",
                state: "Ex-President-elect of the Romanian Society of Cardiology",
                company:"Owner Cardiomed | Co- Founder & Co-Owner Cardioscience",
                image: "my-images/team/8.png",
                comment:
                    "The future Holter looks like this! When it comes to cardiac activity, the type of monitor is as crucial as the duration of the monitoring time. This wireless cardiac patch is far more effective than traditional bulky hardware monitors when it comes to detecting cardiac arrhythmias. Not only is it wireless, it is waterproof, less expensive, patients can be monitored in real time for up to 14 days, and it allows the user to continue their normal lifestyle."
            },
            {
                name: "Daniela Tatu Chitotiu",
                state: "CEO Dahna",
                company:"Co-founder & CEO at CARDIOSCIENCE",
                image: "my-images/team/daniela.jpg",
                comment:
                    "This patch is the right solution, at the right time. With such a product you can become a real leader in this complicated but fascinating business area such as medicine."
            },
        ]
    }

    const carouselConfig = {
        responsiveClass: true,
        items: 2,
        loop: true,
        autoplay: true,
        margin: 10,
        className: "owl-theme",
        responsive: {
            0: {
                items: 1
            }
        }
    }

    return <section className="section6 ptb-100">

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-sm-8">
                    <div className="section-heading text-center mb-5 ">
                        <h1 className="h5 medical-color spaceing"> <b>WHAT THE EXPERTS SAY</b></h1>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-8">
                    <ReactOwlCarousel
                        responsiveClass={carouselConfig.responsiveClass}
                        items={carouselConfig.items}
                        loop={carouselConfig.loop}
                        autoplay={carouselConfig.autoplay}
                        margin={carouselConfig.margin} className={carouselConfig.className}
                        responsive={carouselConfig.responsive}
                    >
                        {(testimonial.comments || []).map(
                            (comment, index) => {
                                return <div key={index} className="item"><Testimonial comment={comment} index={index}/></div>
                            }
                        )}
                    </ReactOwlCarousel>
                </div>

            </div>
        </div>
    </section>
}


export function Testimonial({comment, index}: {comment: Comment, index: number}) {
    return <div className="item testimonial-item" key={index}>
        <div className="testimonial-wrap">
            <blockquote className="mb-4 mt-4 lead">
                <i>{comment.comment}</i>
            </blockquote>
            <div className="media author-info">
                <div className="author-img mr-3">
                    <img
                        src={comment.image}
                        alt={"testimonial " + comment.name}
                        width="179"
                        height="179"
                        className="img-fluid rounded-circle"
                    />
                </div>
                <div className="media-body">
                    <h1 className="h5 mb-0 testimonial-name">{comment.name}</h1>
                    <span>{comment.state}<br/></span>
                    <span>{comment.company}</span>
                </div>
            </div>
        </div>
    </div>
}

