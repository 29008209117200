import "./css/Section4.scss"

export default function Section4() {

    return <section className="section4 promo-section pt-100 pb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 description-col">
                    <div className="justify-content-center">
                        <h1 className="h2 medical-color semibold"> Stages of heart disease</h1>
                        <p>NYHA New York Heart
                            Association Classification</p>
                    </div>
                </div>
                <div className="col-lg-9 col-md-6 img-col">
                    <img className="img-fluid roadmap-image" src="my-images/04-diagrama-NYHA.svg" width="746" height="286" alt="Stages of heart disease"/>
                </div>
            </div>
        </div>
    </section>

}