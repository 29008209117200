import React from "react";
import "./css/NewsSection.scss"
import {Link} from "react-router-dom";
import news from "../data/news.json"
import {NewsType} from "../data/NewsType";

export default function NewsSection() {

    return <section className="newsSection pt-5 pb-100 gray-light-bg" id="about">
        <div className="container">
            <div className="row justify-content-center news-title">
                <h1 className="h5 medical-color spaceing semibold">Latest News</h1>
            </div>
`
            <div className="row equal mt-5 justify-content-md-center ">
                {news.items.slice(0,3).map((item: NewsType) => {
                    return <NewsCard key={item.title} item={item} />;
                })}
            </div>

            <div className="more-news-row row jutify-content-end mb-0">
                <div className="ml-auto mr-3">
                    <Link to="/news">
                        <button className="btn btn-primary">
                            More
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    </section>


    function NewsCard({item}:{item: NewsType}) {

        return<div className=" col-md-4 col-lg-4 newscard">
            <div className="single-promo single-promo-hover h-100" >
                <div >
                    <img

                        src={item.image}
                        alt="promo"
                        className="img-fluid img-full-box card-img-top"
                        height="10px"

                    />
                </div>
                <div className="card-title ml-2">
                    <h5 className=" align-content-start " >{item.title}</h5>
                </div>
                <div className="card-text ml-2 mb-5" style={{color: "grey"}}>
                    <p className="mb-0">Published by {item.publishedBy}</p>
                    <p>{item.date} | {item.type}</p>
                </div>
                <div className="read-more-link mt-5 text-center fixed-bottom">
                    <Link to={"/news/content/" + item.title}>
                        <p className="medical-color">Read More</p>
                    </Link>
                </div>
            </div>
        </div>

    }
}