import HeaderSection from "../../sections/HeaderSection";
import Section13 from "../../sections/Section13";
import FooterSection from "../../sections/FooterSection";
import React from "react";
import "./NewsContent.scss";
import news from "../../data/news.json";
import {NewsType} from "../../data/NewsType";
import ReactMarkdown from 'react-markdown'


export default function NewsContent({id}: {id: string}) {

    const article: NewsType | undefined = news.items.find((item) => item.title == id);



    return <div className="newsContent">
        <HeaderSection/>

        <div className="container content-container shadow mb-5 bg-white rounded">

            {(article == undefined) && <div className="articleNotFound  "><h2 className="text-center  medical-color">Article Not Found</h2></div>}

            {article && <>
                <div className="title gray-light-bg justify-content-center our-team">
                    <h1 className="h2 spaceing semibold">
                        {article.title}
                    </h1>
                    <h5>Published by {article.publishedBy} | {article.date} | {article.type}</h5>
                </div>
                <div className="content">
                    <div className="content-image">
                        <img
                            src={article.image}
                            alt="promo"
                            className="img-fluid img-full-box rounded mx-auto d-block"
                        />
                    </div>
                    <div className="content-description">
                        <div className="mt-5">
                            {article.fullArticle.length!=0 && article.fullArticle.map(p => {
                                return <ReactMarkdown key={p}>{p}</ReactMarkdown>
                            })}
                            {article.fullArticle.length==0 && article.summary.map(p => {
                                return <ReactMarkdown key={p}>{p}</ReactMarkdown>})
                            }
                        </div>
                    </div>
                    {article.fullArticleLink && <div>
                        <a href={article.fullArticleLink}>Full Article can be found here</a>
                    </div>}
                </div>
            </>}
        </div>

        {/*<div className="pt-100 pb-5">*/}
        {/*    <section className="pt-5 pb-5 mr-3 ml-3">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row justify-content-center our-team mb-0">*/}
        {/*                <h1 className="h2 medical-color spaceing semibold">*/}
        {/*                    Patru noi startup-uri se alătură programului Orange Fab*/}
        {/*                </h1>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </section>*/}
        {/*</div>*/}
        {/*<div className="container mt-2 mb-3">*/}
        {/*    <section className="newsSection">*/}
        {/*        <div className="container">*/}
        {/*            <div className="mt-4">*/}
        {/*                <img*/}
        {/*                    src="news/orange-fab.jpg"*/}
        {/*                    alt="promo"*/}
        {/*                    className="img-fluid img-full-box rounded mx-auto d-block"*/}
        {/*                />*/}
        {/*            </div>*/}
        {/*            <div className="mt-5">*/}
        {/*                <p> Orange anunță includerea în*/}
        {/*                    programul de accelerare Orange Fab a patru noi startup-uri ce oferă soluții din zona de*/}
        {/*                    telemedicină, afișaj digital, semnalizarea inteligentă a trecerilor de pietoni și*/}
        {/*                    gestionarea locurilor de parcare pentru bliciclete prin intermediul unei aplicații.</p>*/}
        {/*                <p>Prin alăturarea celor patru startup-uri de tehnologie, acceleratorul Orange Fab ajunge la un*/}
        {/*                    număr total de 44 de startup-uri înscrise în program din 2017 și până în prezent. În cei 5*/}
        {/*                    ani,*/}
        {/*                    Orange Fab și-a extins constant domeniile de activitate pentru a răspunde mai eficient*/}
        {/*                    nevoilor*/}
        {/*                    de business ale clienților, vizând direcții precum rețelele 5G și rețelele viitorului, IoT*/}
        {/*                    și*/}
        {/*                    obiecte conectate, smart city și mobilitate, fintech, smart retail, media și divertisment,*/}
        {/*                    sănătate și educație digitală. Orange Fab a achiziționat, până în prezent, produse și*/}
        {/*                    servicii*/}
        {/*                    în valoare de 2,2 milioane de euro de la startup-urile incluse în program, implementând*/}
        {/*                    alături*/}
        {/*                    de acestea peste 90 de proiecte.</p>*/}
        {/*                <p><b>Blume Technologies</b> adresează problema numărului mare de accidente ce au loc pe*/}
        {/*                    trecerile*/}
        {/*                    de pietoni nesemaforizate, acestea reprezentând 25% din totalul accidentelor în care*/}
        {/*                    pietonii*/}
        {/*                    sunt implicați în România. Soluția dezvoltată oferă un sistem de avertizare pentru trecerile*/}
        {/*                    de*/}
        {/*                    pietoni nesemaforizate bazat pe inteligența artificială. Acesta detectează comportamentul*/}
        {/*                    pietonilor și atenționează șoferii în legătură cu prezența pietonilor și partea de carosabil*/}
        {/*                    de*/}
        {/*                    pe care vor să traverseze. De asemenea, sistemul înregistrează zona trecerii de pietoni și*/}
        {/*                    colectează date de trafic care sunt furnizate sub formă de statistici către autoritățile*/}
        {/*                    publice.</p>*/}
        {/*                <p><b>CardioMedive</b> a dezvoltat un dispozitiv inovator Hospital@Home multifunctional, ieftin*/}
        {/*                    și*/}
        {/*                    ușor de utilizat pentru toată familia. Designul modular îl face ideal pentru o gamă largă de*/}
        {/*                    scenarii și afecțiuni multiple, inovând modul în care este furnizată asistența medicală*/}
        {/*                    pentru*/}
        {/*                    pediatrie, asistență primară sau boli cronice complexe. Soluția dezvoltată de CardioMedive*/}
        {/*                    integrează într-un singur loc toată călătoria medicală a pacientului, de la screening-ul*/}
        {/*                    bolii,*/}
        {/*                    telediagnostic, monitorizarea continuă și examinarea clinică a pacientului de acasă.</p>*/}
        {/*                <p><b>Rastel.io</b> își propune să rezolve problema locurilor de parcare pentru biciclete prin*/}
        {/*                    crearea unei rețele smart de parcări sigure. Prin intermediul aplicației mobile rastel.io,*/}
        {/*                    bicicliștii pot găsi și accesa cu ușurință toate parcările din rețea, fără a avea nevoie de*/}
        {/*                    mai*/}
        {/*                    multe chei sau cartele de acces. De asemenea, aplicația oferă companiilor posibilitatea de a*/}
        {/*                    se*/}
        {/*                    promova, prin crearea de campanii de încurajare și recompensare a mersului pe bicicletă.</p>*/}
        {/*                <p><b>Spectro:o</b> este o soluție completă de afișaj digital ce permite personalizarea*/}
        {/*                    experienței*/}
        {/*                    de*/}
        {/*                    cumpărături din magazine și comunicarea atât cu clienții, cât și cu angajații. Printr-o*/}
        {/*                    platformă digitală intuitivă, Spectro:o ajută branduri din domenii precum retail, HoReCa,*/}
        {/*                    sănătate și producție să gestioneze și să distribuie conținut multimedia pe un număr*/}
        {/*                    nelimitat*/}
        {/*                    de ecrane, în oricâte puncte de vânzare este necesar. Soluția se adresează companiilor care*/}
        {/*                    caută să îmbunătățească și să personalizeze experiența în punctele de prezență și, implicit,*/}
        {/*                    să-și crească vânzările prin afișaj digital de impact.</p>*/}
        {/*                <p>Prin intermediul Orange Fab, toate startup-urile beneficiază de mentorat pentru dezvoltarea*/}
        {/*                    produselor și definitivarea planului de afaceri alături de experții Orange și vor primi*/}
        {/*                    suport*/}
        {/*                    pentru granturi de cercetare și pentru implementarea proiectelor pilot alături de companii*/}
        {/*                    și de*/}
        {/*                    autoritățile publice. De asemenea, au acces în laboratorul Orange 5G Lab la echipamente și*/}
        {/*                    tehnologii de comunicații de ultimă generație, pentru testarea și optimizarea soluțiilor*/}
        {/*                    bazate*/}
        {/*                    pe tehnologiile 5G și IoT.</p>*/}
        {/*                <p>Mai multe informații despre oportunitățile oferite de programul de accelerare și despre*/}
        {/*                    startup-urile ce fac parte din Orange Fab sunt disponibile aici:*/}
        {/*                    <a href="https://www.orangefab.ro/programul-de-accelerare-startup/"> https://www.orangefab.ro/programul-de-accelerare-startup/</a>*/}
        {/*                </p>*/}
        {/*                <p>Articolul oficial se poate vizualiza pe site-ul Orange la adresa: <a*/}
        {/*                    href="https://newsroom.orange.ro/comunicate/patru-noi-startup-uri-se-alatura-programului-orange-fab/">https://newsroom.orange.ro/comunicate/patru-noi-startup-uri-se-alatura-programului-orange-fab/</a>*/}
        {/*                </p>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </section>*/}
        {/*</div>*/}

        <Section13/>

        <FooterSection/>
    </div>
}