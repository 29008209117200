import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Homepage from "./pages/Homepage";
import AgencyCoApp from "./reducers";
import {createStore} from "redux";
import {Provider} from "react-redux";
import Mail from "./pages/Mail";
import Newspage from "./pages/Newspage";
import "../src/data/news.json"
import NewsContent from "./pages/NewsContent/NewsContent";

// create store
const store = createStore(
    AgencyCoApp
);
function AppInner() {


    return <Router>
        <div className="app">
            <div className="content-wrapper">
                <Switch>
                    <Route path="/mail">
                        <Mail/>
                    </Route>
                    <Route
                        path="/news/content/:id"
                        render={({match}) => <NewsContent id={match.params.id}/>}
                    />
                    <Route path="/news">
                        <Newspage/>
                    </Route>
                    <Route path="/">
                        <Homepage/>
                    </Route>
                </Switch>
            </div>
        </div>
    </Router>
}


export default function App() {
    return <>
        <Provider store={store}>
            <AppInner/>
        </Provider>
    </>
}