import React from "react";
import "./css/HeaderSection.scss"
import {Link} from "react-router-dom";

export default function HeaderSection() {
    return <>
        <header className="header">
            <nav className="navbar navbar-expand-lg fixed-top custom-nav white-bg">
                <div className="container">
                    <a className="navbar-brand" href="/" aria-label="Go to homepage">
                        <img src="my-images/00-logo2.svg" width="165" height="41" alt="cardiomedive-logo" className="img-fluid normal-logo"/>
                        <img src="my-images/00-logo2-medical.svg" width="165" height="41" alt="cardiomedive-logo" className="img-fluid medical-logo"/>
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="ti-menu"></span>
                    </button>

                    <div
                        className="collapse navbar-collapse main-menu"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="#home" aria-label="Go to homepage">
                                    HOME
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link to="/news">
                                    <span className="nav-link page-scroll" aria-label="Go to NewsSection">
                                        NEWS
                                    </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="#tehnology" aria-label="Go to technology section">
                                    TECHNOLOGY
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="#whyus" aria-label="Go to why us section">
                                    WHY US
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="#use_cases" aria-label="Go to use cases section">
                                    USE CASES
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="#about" aria-label="Go to about us section">
                                    ABOUT US
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="#contact" aria-label="Go to contact section">
                                    CONTACT
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </>
}