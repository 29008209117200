import React from "react";
import FooterSection from "../sections/FooterSection";
import Section1 from "../sections/Section1";
import Section2 from "../sections/Section2";
import Section3 from "../sections/Section3";
import Section5 from "../sections/Section5";
import Section4 from "../sections/Section4";
import Section6 from "../sections/Section6";
import Section7 from "../sections/Section7";
import Section8 from "../sections/Section8";
import Section9 from "../sections/Section9";
import Section12 from "../sections/Section12";
import Section11 from "../sections/Section11";
import Section10 from "../sections/Section10";
import HeaderSection from "../sections/HeaderSection";
import Slider from "../sections/Slider";
import Section13 from "../sections/Section13";
import Newsletter from "../sections/Newsletter";
import ContactUsPageSection from "../components/Contact/ContactUs";
import JoinOurTeam from "../sections/JoinOurTeam";
import NewsSection from "../sections/NewsSection";
export default function Homepage(){
    return <div>
        <HeaderSection/>
        <div className="main">
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
            <Section6/>
            <Section7/>
            <Slider/>
            <Section8/>
            <Section9/>
            <NewsSection/>
            <Section10/>
            {/*<Newsletter/>*/}
            <Section11/>
            <JoinOurTeam/>
            <Section12/>
            <Section13/>
        </div>
        <FooterSection />
    </div>
}