import "./css/Section7.scss"
import React from "react";

export default function Section7() {

    return <section className="section7 promo-section pb-100 gray-light-bg" id="tehnology">
        <div className="container ">

            <div className="row description">
                <div className="col-md-8 col-lg-8">
                    <p className="lead text-center semibold">
                        <b>We’ve designed, engineered and built award-winning device &
                            biosensors, from the ground
                            up.</b> Patent pending.
                    </p>
                </div>
                <div className="col-md-3 col-lg-3 img-container">
                    <img
                        className="img-fluid d-block animation-one"
                        width="417"
                        height="434"
                        src="my-images/05-imagine-insigna-blurry-patent.png"
                        alt="cardiomedive badge patent"
                    />
                </div>
            </div>

            <div className="row equal items">

                <div className="col-lg-3 col-md-6 col-sm-12 item margin-bottom-when-md">
                    <div
                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg h-100 p-4">
                        <img src="my-images/06-icon-1.svg" className="img-fluid centre" width="124"
                             height="150" alt="Innovative Hardware Design"/>
                        <h1 className="h5 title-short lead"><b>Innovative Hardware Design
                            with dedicated SoC for AI
                            CNN and Sensor Geometry
                            optimized for ultra-low
                            power always on real-time
                            processing</b></h1>
                        <p className="continut-short">Innovative LED geometry design and
                            learn on-board processing for low
                            power consumption</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 item margin-bottom-when-md">
                    <div
                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg h-100 p-4">
                        <img src="my-images/06-icon-2.svg" className="img-fluid centre"  width="124"
                             height="150" alt="Signal Processing and
                            Filtration"/>
                        <h1 className="h5 title-short lead"><b>Signal Processing and
                            Filtration </b></h1>
                        <p className="continut-short">Patent-pending proprietary signal
                            processing for noise filtering,
                            cancellation of motion artifacts
                            increasing signal noise ratio conducting
                            to remarkably clean of acquired ECG
                            and PPG signals</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 item">
                    <div
                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg h-100 p-4">
                        <img src="my-images/06-icon-3.svg" className="img-fluid centre"  width="124"
                             height="150" alt="Employing Multiple Light
                            Spectrums"/>
                        <h1 className="h5 title-short lead"><b>Employing Multiple Light
                            Spectrums</b></h1>
                        <p className="continut-short">Patent-pending proprietary LED sensor
                            design, exploiting various light
                            spectrums for robust dynamic range
                            and signal to noise ratio </p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 item">
                    <div
                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg h-100 p-4">
                        <img src="my-images/06-icon-4.svg" className="img-fluid centre"
                             width="124"
                             height="150"
                             alt="Pioneering algorithms"
                        />
                        <h1 className="h5 title-short lead"><b>Pioneering algorithms</b></h1>
                        <p className="continut-short">Proprietary algorithms for continuous and
                            accurate blood pressure acquired from
                            PPG waves and arrhythmia detection and
                            classification from ECG sensor</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

}