import "./css/Section11.scss"
import React from "react";

export default function Section11() {

    return <section className="section11 pt-5 pb-100 gray-light-bg">
        <div className="container">
            <div className="row row-no-gutters justify-content-center image-row">
                <img className="img-fluid d-block roadmap-image"
                     src="my-images/roadmap-no-font.svg"
                     width="267"
                     height="150"
                     alt="cardiomedive roadmap"
                />
            </div>
        </div>
    </section>

}